<template>
  <div class="space-y-4">
    <asom-alert v-if="error" :error-message="error" variant="error" />
    <div v-if="!isLoading" class="space-y-4">
      <asom-card title="List of TE Bags ready for transfer from PSC to AFC">
        <asom-alert class="mb-4">TE bag(s) will be moved into AFC directly.
        </asom-alert>
        <asom-client-table
          :columns="tableData.columns"
          :data="tableData.data"
          :filterable="false"
          :pagination="false"
        >
          <template v-slot:teBagNo="{ data, rowData }">
            <div class="space-x-2">
              <span :class="rowData.isTeBagDeleted && 'line-through'">{{ data }}</span>
              <asom-badge v-if="rowData.lastTransaction === 'Move Out'">
                In Transit
              </asom-badge>
            </div>
          </template>
          <template v-slot:amount="{ data }">{{
            formatCurrencyCustom(data)
          }}</template>
          <template v-slot:header_select>
            <input
              type="checkbox"
              v-model="multiSelectAll"
              @change="onSelect"
            />
          </template>
          <template v-slot:select="scopedSlots">
            <input
              class="mx-2"
              type="checkbox"
              v-model="scopedSlots.rowData.select"
              @change="onSelect"
            />
          </template>
          <template v-slot:dateCreated="scopedSlots">
            <span>{{ displayUtcDate(scopedSlots.data) }}</span>
          </template>
        </asom-client-table>
        <div class="flex flex-row-reverse pt-4 gap-4">
          <asom-button
            @click="showModal = true"
            :disabled="disableConfirmation"
            :loading="isLoading"
            text="Confirm"
          />
          <asom-button
            text="Cancel"
            variant="secondary"
            @click="$router.push('/cash-mgnt/cash-declaration-form')"
          />
        </div>
      </asom-card>
    </div>
    <div v-else class="text-center">
      <asom-icon icon="spinner" class="animate-spin" />
    </div>
    <asom-modal title="Confirmation" v-model="showModal" :dismissible="false">
      <p class="pt-4">This would move following Te Bags into the AFC </p>
      <ul class="py-2">
        <li v-for="teBag in this.selectedForms" :key="teBag.teBagNo">{{ teBag.teBagNo }}</li>
      </ul>
      <p>Please select Confirm to proceed</p>
      <div class="flex flex-row-reverse pt-4">
        <div class="pl-4">
          <asom-button
            @click="onSubmit"
            :disabled="isSubmitting"
            :loading="isSubmitting"
            text="Confirm"
          />
        </div>
        <div>
          <asom-button
            @click="showModal = false"
            text="Cancel"
            variant="secondary"
          />
        </div>
      </div>
    </asom-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import get from "lodash.get";
import filter from "lodash.filter";
import map from "lodash.map";
import { displayUtcDate } from "@/helpers/dateTimeHelpers";
import {
  getCashDeclarationFormList,
  moveTEBagsPSC,
} from "@/services/cashManagement.service";
import { formatCurrency } from "../../../helpers/numbers";

export default {
  name: "CashDeclarationMoveCashBags",
  data() {
    return {
      cdfFormList: [],
      isLoading: true,
      selectedForms: [],
      showModal: false,
      isSubmitting: false,
      error: null,
    };
  },
  computed: {
    ...mapGetters({
      userId: "auth/userId",
      stationId: "selectedStation/id",
      lineId: "selectedStation/lineId",
      afcId: "cashManagement/stationCashAccounts/afcId",
      pscId: "cashManagement/stationCashAccounts/pscId",
      rosterPlanDate: "currentRoster/currentRosterPlanDate",
      rosterDws: "currentRoster/currentRosterShift",
    }),
    disableConfirmation() {
      return !this.isLoading && !this.selectedForms.length > 0 ? true : false;
    },
    tableData() {
      return {
        columns: [
          "select",
          "teBagNo",
          "dateCreated",
          "referenceNo",
          "amount",
          "sourceOfCash",
        ],
        data: this.cdfFormList.map((transaction) => ({
          select: false,
          ...transaction,
        })),
      };
    },
    multiSelectAll: {
      get() {
        return this.cdfFormList.every((transaction) => transaction.select);
      },
      set(newValue) {
        this.cdfFormList = this.cdfFormList.map((transaction) => ({
          ...transaction,
          select: newValue,
        }));
      },
    },
  },
  mounted() {
    this.getPageData();
  },
  watch: {
    pscId(newId, oldId) {
      if (newId !== oldId) this.getPageData();
    },
  },
  methods: {
    formatCurrency,
    displayUtcDate,
    formatCurrencyCustom(value) {
      if (value) return formatCurrency(value);
      return "N.A";
    },
    onSelect() {
      const newValue = [];
      this.$nextTick(() => {
        this.tableData.data.forEach((transaction) => {
          if (transaction.select) newValue.push(transaction);
        });
        this.selectedForms = newValue;
      });
    },
    async getPageData() {
      const resp = await getCashDeclarationFormList({stationId: this.stationId, lineId: this.lineId});
      if (resp.success) {
        this.cdfFormList = filter(get(resp.payload, "list", []), {
          formStatusEnum: 4,
          isTeBagMovedOut: false,
        });
      }
      this.isLoading = false;
    },
    async onSubmit() {
      let selectedBags = map(this.selectedForms, "teBagNo").map((bagNo) => {
        return {
          bagNo: bagNo,
          remarks: "CDF Completed",
          movingIn: false,
        };
      });
      this.error = null;
      this.isSubmitting = true;
      const result = await moveTEBagsPSC({
        AFCId: this.afcId,
        PSCId: this.pscId,
        cashBagList: selectedBags,
        rosterPlanDate: this.rosterPlanDate,
        rosterDWS: this.rosterDws,
      });
      this.isSubmitting = false;
      if (!result.success) {
        this.error = result.payload;
        this.showModal = false;
        this.$scrollTop();
      } else {
        this.$router.push("/cash-mgnt/cash-declaration-form");
      }
    },
  },
};
</script>
